import { Show, Component } from "solid-js";

export const Colour: Component<{
  class?: string;
  colour?: string;
  current?: boolean;
  metallic?: boolean;
  size?: "sm" | "md" | "lg";
}> = (props) => {
  const size = () => {
    switch (props.size) {
      case "md":
        return "w-8 h-8";
      case "lg":
        return "w-10 h-10";
      case "sm":
      default:
        return "w-5 h-5";
    }
  };

  return (
    <Show
      when={props.metallic}
      fallback={
        <div
          class={`${size()} aspect-square rounded-full`}
          classList={{
            [`${props.class}`]: !!props.class,
            "border box-content": props.colour === "rgba(255, 255, 255, 1)" && !props.current,
            "border-2 box-border border-roma-blue": !!props.current,
          }}
          style={{
            "background-color": props.colour ? props.colour : "purple",
          }}
        />
      }
    >
      <div
        class={`${size()} aspect-square rounded-full`}
        classList={{
          [`${props.class}`]: !!props.class,
        }}
        style={{
          background: `repeating-conic-gradient(rgba(255,255,255,1) 0deg, ${props.colour} 90deg, rgba(255,255,255,1) 180deg)`,
        }}
      />
    </Show>
  );
};