import {
  Component,
  Show,
  Setter,
} from "solid-js";
import {
  useSearchParams,
  A,
  AccessorWithLatest,
  createAsync,
} from "@solidjs/router";
import { BaseLoader } from "~/components/utility";
import { isNullUndefinedOrEmpty } from "~/utils/helpers";
import { Motion } from "solid-motionone";
import { SwatchDisplay } from "./SwatchDisplay";
import { GridSelector } from "./GridSelector";
import { GridSelectorOption } from "./GridSelector";
import { useSessionContext } from "~/utils/contexts";
import { framePayloadGenerator } from "~/utils/builtFrames";
import { Product } from "~/services/roma-api/products/types";
import { useBulkFrameEstimates } from "~/services/cart";
import { GALLERYFRAME, PHOTOFRAME } from "~/utils/products";
import { Permission } from "~/services/roma-api/account/types";



type PFGFPricingBoxProps = {
  product: AccessorWithLatest<Product | undefined>;
  sizes: { code: number | string; width: number; height: number }[];
  sizeOptions: GridSelectorOption[];
  activeSize: () => string;
  productType: typeof GALLERYFRAME | typeof PHOTOFRAME;
  sku: string;
  sizeGuide?: VoidFunction;
  showSizeGuide?: boolean;
  setPricing?: Setter<any>;
};

// TODO: Deal with .loading and .error

export const PFGFPricingBox: Component<PFGFPricingBoxProps> = (props) => {
  const { session, isPartner, hasPermission } = useSessionContext();
  const [params, setParams] = useSearchParams();
  
  const framePricing = createAsync(async () => {
    const data = await useBulkFrameEstimates(props.sku, props.productType);

    const priceDict = [...props.sizes].reduce((memo, item, index) => {
      const pricing = data[index];
      memo[item.code] = {
        Amount: pricing.Amount,
        Tax: pricing.Tax,
        Discount: pricing.Discount,
        Currency: pricing.Currency,
      };
      return memo;
    }, {} as Record<string, { Amount: number; Tax: number; Discount: number; Currency: "CAD" | "USD" | null }>);
    if (props.setPricing) {
      props.setPricing(priceDict);
    }
    return priceDict;
  });

  const PriceLayout: Component<{
    amount: number;
    discount: number;
  }> = (props) => {
    const discount = () => Math.abs(props?.discount);
    return (
      <Motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Show
          when={!isNullUndefinedOrEmpty(props.amount) && !isNaN(props.amount)}
          fallback={
            <span class="text-sm text-roma-dark-grey font-light">
              Error fetching pricing info, please{" "}
              <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                call us
              </a>{" "}
              for further information on this product.
            </span>
          }
        >
          <span class="text-xl font-bold">
            {!params.size ? "From " : ""}${props.amount.toFixed(2)}/ea
          </span>
          <Show when={discount() > 0}>
            <span class="ml-3 text-base  text-roma-medium-grey line-through">
              ${(discount() + props.amount).toFixed(2)}
            </span>
            {/* DISCOUNT PERCENTAGE - to be replaced by percentage value from SAP */}
            {/* <span class="ml-3 text-red-500 font-bold text-base">
                {Math.floor((discount() / (props.amount + discount())) * 100)}%
                off
              </span> */}
          </Show>
        </Show>
      </Motion.div>
    );
  };

  return (
    <div class=" w-full pt-2">
      <Show
        when={hasPermission(Permission.ViewPricing)}
        fallback={<div class="border-t" />}
      >
        <div class="py-3 border-t">
          {/* TODO: HAndle Errors.. */}
          <Show
            when={!framePricing.loading && !framePricing.error}
            fallback={
              <>
                <Show when={framePricing.loading}>
                  <div class="flex items-center">
                    <BaseLoader height={5} width={5} />
                    <p>Retrieving pricing information</p>
                  </div>
                </Show>
                <Show when={framePricing.error}>
                  <div class="flex items-center bg-red-50 text-sm p-2 rounded-md ">
                    <p class="text-center w-full">
                      Kindly contact{" "}
                      <A href="/support" class="text-roma-blue">
                        customer support
                      </A>{" "}
                      for pricing information
                    </p>
                  </div>
                </Show>
              </>
            }
          >
            <PriceLayout
              amount={framePricing()?.[props.activeSize()]?.Amount!}
              discount={framePricing()?.[props.activeSize()]?.Discount!}
            />
          </Show>
        </div>
      </Show>
      <SwatchDisplay product={props.product} type={props.productType} />
      <div>
        <div class="flex justify-between items-center">
          <p class="text-roma-dark-grey text-sm">Select Size</p>
          <Show when={props.sizeGuide}>
            <button
              class="cursor-pointer text-sm px-3  text-roma-dark-grey border border-roma-dark-grey rounded-full hover:bg-roma-dark-grey hover:text-white transition"
              onClick={props.sizeGuide}
            >
              Size Guide
            </button>
          </Show>
        </div>
        <GridSelector
          options={props.sizeOptions}
          onClick={(val) => {
            setParams({ size: val.toString() }, { replace: true });
          }}
          activeValueComparison={params.size}
        />
      </div>
    </div>
  );
};
