import { Accessor, Component, Setter, Show } from "solid-js";
import { Icon } from "solid-heroicons";
import { plus, minus } from "solid-heroicons/outline";
import { TextFieldInput } from "~/components/inputs";

export const FrameCounter: Component<{
  disabled?: string;
  minimum?: number;
  updateCount: Setter<number>;
  getCount: Accessor<number>;
  label?: string;
  class?: string;
}> = (props) => {
  return (
    <div classList={{ [`${props.class}`]: !!props.class }}>
      <Show when={props.label}>
        <p class="text-sm text-roma-dark-grey py-2">{props.label}</p>
      </Show>
      <div class="flex justify-around w-full">
        <button
          aria-label="Decrement quantity by 1"
          class="p-2 border border-r-0 border-gray-300 disabled:text-roma-medium-grey disabled:cursor-not-allowed"
          disabled={props.minimum == props.getCount()}
          onClick={() => {
            props.updateCount(props.getCount() - 1);
          }}
        >
          <Icon path={minus} class="w-4" />
        </button>
        <TextFieldInput
          name="quantity"
          type="number"
          onChange={(val) => props.updateCount(Number(val))}
          value={props.getCount().toString()}
          class="rounded-none text-center"
        />
        <button
          aria-label="Increment quantity by 1"
          class="p-2 border border-l-0 border-gray-300"
          onClick={() => {
            props.updateCount(props.getCount() + 1);
          }}
        >
          <Icon path={plus} class="w-4" />
        </button>
      </div>
    </div>
  );
};
