import {
  createEffect,
  createMemo,
  Show,
  createSignal,
  For,
  Switch,
  Match,
  on,
  onMount,
  type Component,
} from "solid-js";
import { AccessorWithLatest, useSearchParams } from "@solidjs/router";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { PHOTOFRAME, GALLERYFRAME, MOULDING, MIRROR } from "~/utils/products";
import { Icon } from "solid-heroicons";
import {
  magnifyingGlassMinus,
  magnifyingGlassPlus,
  arrowPathRoundedSquare,
} from "solid-heroicons/outline";
import { Img } from "~/components/image";
import { ProductImage } from "~/components/product";
import { Menu } from "~/components/utility";
import { capitalize } from "~/utils/helpers";
import { Carousel } from "~/components/ui";
import { Product } from "~/services/roma-api/products/types";
import { isServer } from "solid-js/web";

type PDPImageGalleryProps = {
  type: string;
  // TODO! Properly type this...
  product: AccessorWithLatest<Product | undefined>;
};

export const PDPImageGallery: Component<PDPImageGalleryProps> = (props) => {
  const [params, setParams] = useSearchParams();
  const [showZoomModal, setShowZoomModal] = createSignal(false);
  const [zoomFocus, setZoomFocus] = createSignal();
  const { isPartner } = useSessionContext();
  const { breakpoints } = useSiteContext();

  const orientation = () => {
    if (props.product()?.ProductType !== "Stretcher") return "";
    if (params.orientation === "gallery") {
      return "-g";
    } else {
      return "-s";
    }
  };
  // TODO - height/width not being used?
  const generateImages = (height: number = 620, width: number = 620) => {
    let imgs: any = {};
    let padding = [3, 3, 3, 3];
    switch (true) {
      case props.type === GALLERYFRAME:
        padding = [0, 0, 0, 0, 0];
        imgs = {
          img1: `${import.meta.env.VITE_ROMA_CDN}/gallery-frames/${
            props.product()?.SKU
          }-1.png`,
          img2: `${import.meta.env.VITE_ROMA_CDN}/gallery-frames/${
            props.product()?.SKU
          }-2.png`,
          img3: `${import.meta.env.VITE_ROMA_CDN}/gallery-frames/${
            props.product()?.SKU
          }-3.png`,
          img4: `${import.meta.env.VITE_ROMA_CDN}/gallery-frames/${
            props.product()?.SKU
          }-4.png`,
          img5: `${import.meta.env.VITE_ROMA_CDN}/gallery-frames/back.png`,
        };
        break;
      case props.type === PHOTOFRAME:
        padding = [12, 0, 0, 0, 2];
        imgs = {
          img1: `${import.meta.env.VITE_ROMA_CDN}/photo-frames/${
            props.product()?.SKU
          }-1.png`,
          img2: `${import.meta.env.VITE_ROMA_CDN}/photo-frames/${
            props.product()?.SKU
          }-2.png`,
          img3: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-5.png`,
          img5: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-4.png`,
          img4: `${import.meta.env.VITE_ROMA_CDN}/photo-frames/back.png`,
        };
        break;
      case props.type === MOULDING && !!props.product()?.Video:
        padding = [0, 0, 0, 3, 6];
        imgs = {
          img1: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-2.png`,
          img2: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-5.png`,
          img3: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-4.png`,
          img4: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-3.png`,
          img5: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-1.png`,
        };
        break;
      default:
        padding = [0, 0, 6, 0, 3];
        imgs = {
          img1: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-2${orientation()}.png`,
          img2: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-5${orientation()}.png`,
          img3: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-1${orientation()}.png`,
          img4: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-4${orientation()}.png`,
          img5: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            props.product()?.SKU
          }-3${orientation()}.png`,
        };
    }
    // ! PROFILE IMAGES REMOVED
    if (props.product()?.ProfileFamily) {
      imgs.profileImage = `${import.meta.env.VITE_ROMA_CDN}/profiles/${
        props.product()?.ProfileFamily
      }${orientation()}.svg`;
    }
    return [imgs, padding];
  };
  const images = createMemo(
    on(
      () => [props.product()?.SKU, params.orientation],
      () => generateImages(620, 620)
    )
  );

  const resetImagePadding = (e: Event) => {
    (e.currentTarget as HTMLDivElement).style.padding = "0";
    e.stopPropagation();
  };

  const ZoomModal = () => {
    let parentRef: HTMLDivElement;
    let containerRef: HTMLDivElement;
    const [imageRefs, setImageRefs] = createSignal<HTMLDivElement[]>([]);
    const [active, setActive] = createSignal();
    const [scale, setScale] = createSignal<number | string>(1);

    const onIntersect = (entries: IntersectionObserverEntry[]) => {
      // const [entry] = entries;
      // setActive(entry.target.id);
      entries.forEach((entry) => {
        setActive(entry.target.id);
      });
    };

    const options = {
      root: containerRef!,
      rootMargin: "0px",
      threshold: 0.55,
    };
    createEffect(() => {
      const observer = new IntersectionObserver(onIntersect, options);
      if (imageRefs()) {
        imageRefs().forEach((el) => {
          observer.observe(el);
        });
      }
    });
    const setOverflow = (value = "hidden") => {
      if (window.document.body.style.overflow != value) {
        window.document.body.style.overflow = value;
      }
    };
    createEffect(() => {
      setOverflow(showZoomModal() ? "hidden" : "auto");
    });

    const scroll = (item: number) => {
      // TODO - look for fix
      // doesn't seem to be working consistently
      // ? setTimeout was added because on initial click of a thumbnail, all childNodes had
      // ? not been rendered, and would not scroll to correct image. On subsequent clicks it
      // ? would work normally. This is a temporary workaround.
      setTimeout(() => {
        const height = window.innerHeight;
        const arr = [...containerRef.childNodes].map((el) => {
          if (el instanceof HTMLElement) {
            return { top: el.offsetTop, height: el.offsetHeight };
          } else {
            return { top: 0, height: 0 };
          }
        });
        parentRef.scrollTo({
          top: arr[item].top + (arr[item].height - height) * 0.5,
          behavior: "smooth",
        });
      }, 100);
    };

    createEffect(() => {
      if (zoomFocus()) {
        scroll(zoomFocus() as number);
      }
    });

    return (
      <Show when={showZoomModal() == true}>
        <div
          ref={(ref) => (parentRef = ref)}
          class="fixed inset-0 bg-roma-grey z-50 overflow-y-auto flex flex-col items-center"
        >
          <button
            class="fixed top-4 right-8 text-xl underline-hover underline-black z-20"
            onClick={() => {
              setShowZoomModal(false);
            }}
          >
            CLOSE<span class="font-bold"> &#10005;</span>
          </button>
          {/* THUMBNAILS */}
          <div class="fixed left-0 top-0 bottom-0 flex items-center z-20">
            <div
              class="flex flex-col gap-2 p-2 ml-4 bg-white/80 backdrop-blur-md rounded-lg shadow-lg
              relative"
            >
              <For each={Object.entries(generateImages(60, 60)[0])}>
                {([val, item], index) => (
                  <div
                    class="bg-roma-grey border-2 border-transparent rounded-sm"
                    classList={{
                      "!border-roma-blue":
                        index() == active() && (scale() as number) < 1.1,
                    }}
                    onClick={() => {
                      scroll(index());
                    }}
                  >
                    <Show
                      when={val !== "profileImage"}
                      fallback={
                        <Img
                          src={item as string}
                          width={60}
                          height={60}
                          alt=""
                          loading="lazy"
                          breakpoints={[60]}
                        />
                      }
                    >
                      <ProductImage
                        src={item as string}
                        alt="Product image"
                        width={60}
                        height={60}
                        quality={95}
                        loading="lazy"
                        breakpoints={[60]}
                      />
                    </Show>
                  </div>
                )}
              </For>
            </div>
          </div>
          {/* SCALE SLIDER */}
          <div class="fixed left-0 bottom-0 m-4 p-3 backdrop-blur-md bg-white/80 rounded-lg shadow-lg z-20 flex gap-2">
            <Icon path={magnifyingGlassMinus} width="20" height="20" />
            <input
              type="range"
              min={0.5}
              max={1.5}
              // step={.125}
              step="any"
              value={scale()}
              onInput={(e) => {
                setScale(e.currentTarget.value);
              }}
              class="accent-roma-blue"
            />
            <Icon path={magnifyingGlassPlus} width="20" height="20" />
          </div>
          <div ref={(ref) => (containerRef = ref)} class="">
            <For each={Object.entries(generateImages(2000, 2000)[0])}>
              {([val, item], index) => (
                <div
                  ref={(el) => setImageRefs([...imageRefs(), el])}
                  id={`${index()}`}
                  class="bg-roma-grey w-full 2xl:px-32"
                  style={{ transform: `scale(${scale()})` }}
                >
                  <Show
                    when={val !== "profileImage"}
                    fallback={
                      <Img
                        src={item as string}
                        width={2000}
                        height={2000}
                        alt=""
                        loading="lazy"
                      />
                    }
                  >
                    <ProductImage
                      src={item as string}
                      alt=""
                      width={2000}
                      height={2000}
                      quality={80}
                      loading="lazy"
                      class="w-full"
                    />
                  </Show>
                </div>
              )}
            </For>
          </div>
        </div>
      </Show>
    );
  };

  return (
    <>
      {/* DESKTOP */}
      <div class="hidden md:grid md:grid-cols-2 md:gap-2">
        <For each={Object.entries(images()[0])}>
          {([val, item], index) => (
            <>
              <Show
                when={
                  index() === 1 &&
                  props.product()?.Video &&
                  props.type === MOULDING
                }
              >
                <div class="aspect-square w-full h-full border border-roma-grey ">
                  <video
                    class="w-full object-cover aspect-square"
                    muted
                    autoplay
                    loop
                    src={`${import.meta.env.VITE_ROMA_CDN}/${
                      props.product()?.Video
                    }`}
                  />
                </div>
              </Show>
              <div
                class="w-full h-full aspect-square bg-roma-grey flex justify-center items-center cursor-pointer relative"
                classList={{ [`p-${images()[1][index()]}`]: true }}
                onClick={() => {
                  setShowZoomModal(true);
                  setZoomFocus(index());
                }}
                // @ts-expect-error
                on:imageLoadError={resetImagePadding}
              >
                <Show
                  when={val !== "profileImage"}
                  fallback={
                    <Img
                      src={item as string}
                      width={620}
                      height={620}
                      alt=""
                      loading="lazy"
                      breakpoints={[175, 300, 440, 620]}
                    />
                  }
                >
                  <ProductImage
                    src={item as string}
                    alt=""
                    width={620}
                    height={620}
                    quality={80}
                    loading="lazy"
                    breakpoints={[175, 300, 440, 620]}
                  />
                </Show>

                <Show when={index() === 0}>
                  <Show when={props.product()?.ProductType === "Stretcher"}>
                    <div
                      class="absolute bottom-0 left-0 m-3"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Menu
                        list={[
                          {
                            list: [
                              {
                                label: "Standard",
                                onSelect() {
                                  setParams({ orientation: "standard" });
                                },
                              },
                              {
                                label: "Gallery",
                                onSelect() {
                                  setParams({ orientation: "gallery" });
                                },
                              },
                            ],
                          },
                        ]}
                      >
                        <div class="bg-white border hover:border-gray-400 rounded-lg py-1 pl-2 pr-3 text-xs lg:text-sm xl:text-base text-roma-dark-grey flex items-center">
                          <Icon
                            path={arrowPathRoundedSquare}
                            class="w-5 mr-1"
                          />
                          <span>
                            Orientation:{" "}
                            {params.orientation
                              ? capitalize(params.orientation)
                              : "Standard"}
                          </span>
                        </div>
                      </Menu>
                    </div>
                  </Show>
                  <div class="absolute top-0 left-0 p-3 text-sm flex justify-between w-full">
                    <Switch>
                      <Match when={isPartner() && props.product()?.OnSale}>
                        <div class="flex items-center font-medium text-white px-3 bg-red-500 rounded-full">
                          <p>On Sale</p>
                        </div>
                      </Match>
                      <Match when={props.product()?.ComingSoon}>
                        <div class="flex items-center font-medium text-white px-3 bg-roma-blue rounded-full">
                          <p>Coming {props.product()?.ArrivalDate ?? "Soon"}</p>
                        </div>
                      </Match>
                      <Match when={props.product()?.IsNew}>
                        <div class="flex items-center font-medium text-white px-3 bg-roma-blue rounded-full">
                          <p>New</p>
                        </div>
                      </Match>
                    </Switch>
                    <Show when={props.product()?.Discontinued}>
                      <div class="flex items-center font-medium  px-3 bg-roma-medium-grey rounded-full text-white">
                        <p>Discontinued</p>
                      </div>
                    </Show>
                    <Show when={props.product()?.Discontinuing}>
                      <div class="flex items-center font-medium  px-3 bg-roma-grey rounded-full text-orange-300">
                        <p>Discontinuing</p>
                      </div>
                    </Show>
                  </div>
                </Show>
              </div>
            </>
          )}
        </For>
        <ZoomModal />
      </div>
      {/* MOBILE */}
      <div class="md:hidden col-span-2 relative">
        <Carousel>
          <Show when={props.product()?.ProductType === "Stretcher"}>
            <div
              class="absolute top-2 left-4"
              onClick={(e) => e.stopPropagation()}
            >
              <Menu
                list={[
                  {
                    list: [
                      {
                        label: "Standard",
                        onSelect() {
                          setParams({ orientation: "standard" });
                        },
                      },
                      {
                        label: "Gallery",
                        onSelect() {
                          setParams({ orientation: "gallery" });
                        },
                      },
                    ],
                  },
                ]}
              >
                <div class="bg-white border hover:border-gray-400 rounded-lg py-1 pl-2 pr-3 text-sm text-roma-dark-grey flex items-center">
                  <Icon path={arrowPathRoundedSquare} class="w-5 mr-1" />
                  <span>
                    Orientation:{" "}
                    {params.orientation
                      ? capitalize(params.orientation)
                      : "Standard"}
                  </span>
                </div>
              </Menu>
            </div>
          </Show>
          <For each={Object.entries(images()[0])}>
            {([val, item], index) => (
              <div class="snap-start w-full shrink-0 pl-2 pr-2 first:pl-0 last:pr-0">
                <div
                  class="aspect-square bg-roma-grey flex justify-center items-center"
                  classList={{ [`p-${images()[1][index()]}`]: true }}
                  // @ts-expect-error
                  on:imageLoadError={resetImagePadding}
                >
                  <Show
                    when={val !== "profileImage"}
                    fallback={
                      <Img
                        src={item as string}
                        width={620}
                        height={620}
                        alt=""
                        loading="lazy"
                      />
                    }
                  >
                    <ProductImage
                      src={item as string}
                      alt="Product image"
                      width={620}
                      height={620}
                      quality={80}
                      loading="lazy"
                    />
                  </Show>
                </div>
              </div>
            )}
          </For>
        </Carousel>
      </div>
    </>
  );
};
