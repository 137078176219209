import { Component, For, Show } from "solid-js";
import { A, AccessorWithLatest, useNavigate } from "@solidjs/router";
import { Colour } from "./Colour";
import { pathMapping } from "~/utils/products";
import { Product } from "~/services/roma-api/products/types";
import { useSiteContext } from "~/utils/contexts";
// import { Tooltip } from "~/components/inputs-v2";

type SwatchDisplayProps = {
  product: AccessorWithLatest<Product | undefined>;
  type: string;
  class?: string;
};

export const SwatchDisplay: Component<SwatchDisplayProps> = (props) => {
  const { global } = useSiteContext();
  const navigate = useNavigate();
  return (
    <Show when={props.product() && global()}>
      <div
        class="flex flex-col my-2 mb-4"
        classList={{ [`${props.class}`]: !!props.class }}
      >
        <p class="text-roma-dark-grey text-sm">Available Colours</p>
        <ul class="flex flex-wrap gap-2">
          {/* <Tooltip content={props.currentColourDescription}> */}
          <li class="flex items-center py-2 cursor-pointer">
            <Show when={global().colours[props.product()?.Colour as string]}>
              <Colour
                colour={global().colours[props.product()?.Colour as string][0]}
                metallic={
                  global().colours[props.product()?.Colour as string][1]
                }
                current={true}
                size="md"
              />
            </Show>
          </li>
          {/* </Tooltip> */}
          <For each={props.product()?.AvailableList}>
            {(item) => (
              // <Tooltip content={item.ColourDescription}>
              <li class="flex items-center py-2 cursor-pointer ">
                <A
                  href={`/product/${item.SKU}${pathMapping[props.type] ?? ""}`}
                >
                  <Show when={global().colours[item.Colour]}>
                    <Colour
                      colour={global().colours[item.Colour][0]}
                      metallic={global().colours[item.Colour][1]}
                      current={
                        item.ColourDescription === props.product()?.Colour
                      }
                      size="md"
                    />
                  </Show>
                </A>
              </li>
              // </Tooltip>
            )}
          </For>
        </ul>
      </div>
    </Show>
  );
};
