import { Component, For } from "solid-js";
import { createStore } from "solid-js/store";
import Body from "~/components/Body";

export const FrameChart: Component<{
  mouldingWidth: number;
}> = (props) => {
  const gfSizes = [
    { width: 16, height: 20, matWidth: 8, matHeight: 10, code: 1620 },
    { width: 20, height: 20, matWidth: 11, matHeight: 14, code: 2020 },
    { width: 18, height: 24, matWidth: 12, matHeight: 18, code: 1824 },
    { width: 24, height: 36, matWidth: 20, matHeight: 30, code: 2436 },
  ];

  const [frame, setFrame] = createStore({
    mouldingWidth: props.mouldingWidth ?? 0.75,
    frameWidth: 20,
    frameHeight: 20,
    matWidth: 11,
    matHeight: 14,
    coefficent: 10,
    active: 2020,
    get outerWidth() {
      return this.frameWidth + this.mouldingWidth * 2;
    },
    get outerHeight() {
      return this.frameHeight + this.mouldingWidth * 2;
    },
  });

  const pxSize = (storeKey: string): string => {
    return (
      (frame[storeKey as keyof typeof frame] * frame.coefficent).toString() +
      "px"
    );
  };

  return (
    <Body>
      <div class="max-w-6xl mx-auto flex items-center justify-center">
        <div class="flex max-sm:flex-col py-5 justify-center items-center min-w-[70vw] ">
          {/* LEFT */}
          <div class="sm:w-1/2 py-2 align-middle sm:px-6 lg:px-8 flex justify-end">
            <div>
              <h3 class="text-3xl pb-4">Size Guide</h3>
              <p class="text-lg pb-10 font-light">
                Find your correct size in the chart below.
              </p>
              <table class="max-sm:min-w-full divide-y divide-roma-grey border border-collapse border-roma-grey">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 px-6  text-left text-base font-semibold text-gray-900  border  whitespace-nowrap"
                    >
                      Frame Size
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3.5 text-left text-base font-semibold text-gray-900 border border-l-0 whitespace-nowrap"
                    >
                      Mat Opening
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white ">
                  <For each={gfSizes}>
                    {(item, index) => (
                      <tr
                        class="pointer-events-none md:pointer-events-auto cursor-pointer group"
                        classList={{ "bg-roma-grey": index() % 2 === 0 }}
                        onClick={() => {
                          setFrame({
                            frameWidth: item.width,
                            frameHeight: item.height,
                            matWidth: item.matWidth,
                            matHeight: item.matHeight,
                            active: item.code,
                          });
                        }}
                      >
                        <td
                          class="whitespace-nowrap py-3 pl-6 pr-3 text-base font-medium text-gray-900  border  fraction md:group-hover:text-roma-blue"
                          classList={{
                            "md:text-roma-blue": frame.active === item.code,
                          }}
                        >
                          {item.width} <span class="text-xs">&#10005;</span>{" "}
                          {item.height}"
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-3 text-base text-gray-900 border  fraction group-hover:text-roma-blue"
                          classList={{
                            "md:text-roma-blue": frame.active === item.code,
                          }}
                        >
                          {item.matWidth} <span class="text-xs">&#10005;</span>{" "}
                          {item.matHeight}"
                        </td>
                      </tr>
                    )}
                  </For>
                </tbody>
              </table>
            </div>
          </div>
          {/* RIGHT */}
          <div class="hidden md:flex flex-col max-sm:pt-8  sm:w-1/2 items-start sm:ml-10">
            <div
              class="border border-gray-300 border-b-0 mb-3 transition-all duration-500 flex flex-col items-center justify-end"
              style={{
                width: pxSize("frameWidth"),
                height: "65px",
                "margin-left": pxSize("mouldingWidth"),
              }}
            >
              <p class="text-gray-400 text-xs font-light whitespace-nowrap pb-1">
                Frame Size
              </p>
              <div
                class="border border-gray-400 border-dashed border-b-0 transition-all duration-500 flex justify-center items-end"
                style={{ width: pxSize("matWidth"), height: "30px" }}
              >
                <p class="text-gray-400 text-xs font-light whitespace-nowrap">
                  Mat Opening
                </p>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex">
                <div
                  class="flex justify-center items-center bg-roma-grey border border-gray-300 transition-all duration-500"
                  style={{
                    width: pxSize("outerWidth"),
                    height: pxSize("outerHeight"),
                  }}
                >
                  <div
                    class="flex justify-center items-center bg-white border border-roma-gray-200 transition-all duration-500 "
                    style={{
                      width: pxSize("frameWidth"),
                      height: pxSize("frameHeight"),
                    }}
                  >
                    <div
                      class="border-2 border-dashed border-gray-300 transition-all duration-500"
                      style={{
                        width: pxSize("matWidth"),
                        height: pxSize("matHeight"),
                      }}
                    />
                  </div>
                </div>
                {/* SIDE */}
                <div
                  class="border border-gray-300 border-l-0 ml-3 transition-all duration-500 flex items-center justify-start"
                  style={{
                    height: pxSize("frameHeight"),
                    width: "65px",
                    "margin-top": pxSize("mouldingWidth"),
                  }}
                >
                  <div
                    class="border border-gray-400 border-dashed border-l-0 transition-all duration-500 flex justify-center items-center"
                    style={{ height: pxSize("matHeight"), width: "30px" }}
                  >
                    <p class="text-gray-400 transform -rotate-90 text-xs font-light whitespace-nowrap">
                      Mat Opening
                    </p>
                  </div>
                  <p class="transform -rotate-90 text-gray-400 text-xs font-light whitespace-nowrap pb-6">
                    Frame Size
                  </p>
                </div>
                {/* END OF SIDE */}
              </div>
              <div class="pt-4 flex justify-around items-center gap-4  w-full font-light">
                <div class="flex items-center gap-2">
                  <p>Frame Size</p>
                  <div class="w-10 border-t border-gray-500 " />
                </div>
                <div class="flex items-center gap-2">
                  <p>Mat Opening</p>
                  <div class="w-10 border-t border-dashed border-gray-500 " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};
