export enum ThresholdStatus {
  inStock = "In Stock",
  lowStock = "Low Stock",
  zeroStock = "Sold Out",
}

export enum ThresholdCheckout {
  allow = "Allow",
  backorder = "Backorder",
  block = "Block",
  optionalBackorder = "Optional Backorder"
}

export type ThresholdResult = {
  qtyString: string;
  qtyNum: number;
  dropdownContent: string;
  status: ThresholdStatus;
  checkout: ThresholdCheckout;
};

export const stockThreshold = (
  qty: number,
  category: string,
  isDiscontinued = false
): ThresholdResult => {
  let result: ThresholdResult = {
    qtyString: Math.round(qty).toLocaleString() + " ft",
    qtyNum: qty,
    dropdownContent: Math.round(qty).toLocaleString() + " ft",
    status: ThresholdStatus.inStock,
    checkout: ThresholdCheckout.allow,
  };

  switch (true) {
    case qty <= 10 && isDiscontinued:
      result = {
        ...result,
        qtyString: "",
        dropdownContent: "Sold Out",
        status: ThresholdStatus.zeroStock,
        checkout: ThresholdCheckout.block,
      };
      break;
    case qty <= 0:
      result = {
        ...result,
        qtyString: "",
        dropdownContent: "Sold Out",
        status: ThresholdStatus.zeroStock,
        checkout: ThresholdCheckout.backorder,
      };
      break;
    case category === "Roma Elite" && qty < 10:
      result = {
        ...result,
        dropdownContent: "Sold Out",
        status: ThresholdStatus.zeroStock,
        checkout: ThresholdCheckout.backorder,
      }
      break;
    case category === "Roma One" && qty < 50:
      result = {
        ...result,
        qtyString: "",
        dropdownContent: "Low Stock",
        status: ThresholdStatus.lowStock,
        checkout: ThresholdCheckout.optionalBackorder,
      };
      break;
    case category === "Simply Roma" && qty < 100:
      result = {
        ...result,
        qtyString: "",
        dropdownContent: "Low Stock",
        status: ThresholdStatus.lowStock,
        checkout: ThresholdCheckout.optionalBackorder,
      };
      break;
  }
  return result;
};
